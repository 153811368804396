@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

main {
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.navbar-scroll {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.bebas {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 400;
  font-size: larger;
  font-style: normal;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(150% + 1.3px);
  height: 100px;
}

.wave .shape-fill {
  fill: #FFFFFF;
}

.wave {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave.bottom {
  bottom: 0;
  transform: rotate(180deg);
}

.wave.top {
  top: 0;
}

.container {
  width: 100%;
  max-width: 950px;
  margin: auto;
}

h1 {
  font-weight: 700;
  font-size: xxx-large;
}

h2 {
  font-weight: 700;
  font-size: x-large;
}

h3 {
  font-weight: 700;
  font-size: large;
}